<template>
  <v-dialog
    v-model="dialogClientes"
    @click:outside="$emit('update:dialogClientes', false)"
    @keydown.esc="$emit('update:dialogClientes', false)"
    width="1500px"
    scrollable
  >
    <v-card>
      <PageToolbar
        :title="$tc('global.cliente', 2)"
        icon="mdi-clipboard-account-outline"
        dark
      />

      <ListaClientes dialogClientes />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ClientesDialog",
  components: {
    ListaClientes: () =>
      import("@/views/dashboard/clientes/components/ListaClientes.vue"),
  },
  data: () => ({}),
  props: {
    dialogClientes: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {},
};
</script>

<style></style>
